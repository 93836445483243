// extracted by mini-css-extract-plugin
export var dentist = "team-module--dentist--e81LF";
export var icon = "team-module--icon--cKAUy";
export var image = "team-module--image--RryB-";
export var inner = "team-module--inner--1cAPG";
export var intro = "team-module--intro--Pv5Dz";
export var left = "team-module--left--4X0MO";
export var leftbottom = "team-module--leftbottom--Yudch";
export var lefttop = "team-module--lefttop--l-JLo";
export var link = "team-module--link--ATpxe";
export var linkhover = "team-module--linkhover--PZ+Eh";
export var list = "team-module--list--H-qSu";
export var listitem = "team-module--listitem---O6S-";
export var locationlink = "team-module--locationlink--HFhAj";
export var logo = "team-module--logo--WX99h";
export var logolink = "team-module--logolink---aPVS";
export var name = "team-module--name--UvhEX";
export var right = "team-module--right--MIJIA";
export var screenreader = "team-module--screenreader--arYFE";
export var servicename = "team-module--servicename--uGF1T";
export var teamheading = "team-module--teamheading--tnDSn";
export var teamlist = "team-module--teamlist--MgmN2";
export var top = "team-module--top--565ZK";
export var view = "team-module--view--xHJJ0";